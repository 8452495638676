import { supabase } from '../client';
import type { Conversation } from '../../../types/mastodon';

export async function fetchConversations(
  instanceDomain: string,
  offset: number = 0,
  limit: number = 20
): Promise<Conversation[]> {
  const { data, error } = await supabase
    .from('conversations')
    .select()
    .eq('instance_domain', instanceDomain)
    .order('updated_at', { ascending: false })
    .range(offset, offset + limit - 1);

  if (error) throw error;
  if (!data) return [];

  const conversations = data
    .map(conv => ({
      id: conv.id,
      participants: conv.participants || [],
      lastMessage: conv.last_message ? {
        ...conv.last_message,
        timestamp: conv.last_message.timestamp ? new Date(conv.last_message.timestamp) : new Date()
      } : null,
      unread: conv.unread
    }));

  // Sort conversations by last message timestamp
  conversations.sort((a, b) => {
    const timeA = a.lastMessage?.timestamp instanceof Date ? a.lastMessage.timestamp.getTime() : 0;
    const timeB = b.lastMessage?.timestamp instanceof Date ? b.lastMessage.timestamp.getTime() : 0;
    return timeB - timeA;
  });

  return conversations;
}

export async function fetchConversation(
  conversationId: string,
  instanceDomain: string
): Promise<Conversation | null> {
  const { data, error } = await supabase
    .from('conversations')
    .select()
    .eq('id', conversationId)
    .eq('instance_domain', instanceDomain)
    .single();

  if (error) return null;
  if (!data) return null;

  return {
    id: data.id,
    participants: data.participants,
    lastMessage: data.last_message,
    unread: data.unread
  };
}