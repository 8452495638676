import { getGeoLocation } from '../../api/geo';

export async function getDeviceInfo() {
  const ua = navigator.userAgent;
  const platform = navigator.platform;
  
  // Get geo location
  const geoInfo = await getGeoLocation();
  
  return {
    name: getDeviceName(ua),
    platform: getPlatform(platform, ua),
    browser: getBrowser(ua),
    location: geoInfo ? {
      city: geoInfo.city,
      region: geoInfo.region,
      country: geoInfo.country,
      timezone: geoInfo.timezone
    } : null
  };
}

function getDeviceName(ua: string): string {
  if (/iPhone/.test(ua)) return 'iPhone';
  if (/iPad/.test(ua)) return 'iPad';
  if (/Android/.test(ua)) {
    const match = ua.match(/\(.*?\)/);
    return match ? match[0].replace(/[()]/g, '') : 'Android Device';
  }
  if (/Mac/.test(ua)) return 'Mac';
  if (/Windows/.test(ua)) {
    const match = ua.match(/Windows NT [\d.]+/);
    return match ? match[0] : 'Windows';
  }
  if (/Linux/.test(ua)) return 'Linux';
  return 'Unknown Device';
}

function getPlatform(platform: string, ua: string): string {
  if (/iPhone|iPad|iPod/.test(ua)) return 'iOS';
  if (/Android/.test(ua)) return 'Android';
  if (/Mac/.test(platform)) return 'macOS';
  if (/Win/.test(platform)) return 'Windows';
  if (/Linux/.test(platform)) return 'Linux';
  return platform;
}

function getBrowser(ua: string): string {
  if (/Firefox/.test(ua)) return 'Firefox';
  if (/Chrome/.test(ua)) return 'Chrome';
  if (/Safari/.test(ua)) return 'Safari';
  if (/Edge/.test(ua)) return 'Edge';
  if (/Opera|OPR/.test(ua)) return 'Opera';
  return 'Unknown';
}