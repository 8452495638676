import { useState, useCallback } from 'react';
import { nanoid } from 'nanoid';
import { useMastodonStore } from '../store/mastodonStore';
import { storeMessage } from '../services/supabase/messages/store';
import { generateUserId } from '../utils/user/identifier';

export function useSendMessage() {
  const [isLoading, setIsLoading] = useState(false);
  const instance = useMastodonStore((state) => state.instance);
  const currentUser = useMastodonStore((state) => state.currentUser);
  const activeConversation = useMastodonStore((state) => state.activeConversation);
  const conversations = useMastodonStore((state) => state.conversations);

  const sendMessage = useCallback(async (content: string) => {
    if (!instance || !currentUser || !activeConversation || !content.trim() || isLoading) {
      return false;
    }

    const conversation = conversations.find(c => c.id === activeConversation);
    if (!conversation) return false;

    setIsLoading(true);
    try {
      const messageId = nanoid();
      const recipient = conversation.participants[0];
      
      const message = {
        id: messageId,
        content: content.trim(),
        sender: await generateUserId(currentUser.acct, instance.domain),
        recipient: await generateUserId(recipient.acct, instance.domain),
        timestamp: new Date(),
        conversationId: activeConversation,
        senderInfo: {
          id: currentUser.id,
          username: currentUser.username,
          displayName: currentUser.displayName,
          avatar: currentUser.avatar,
          acct: currentUser.acct,
          url: `https://${instance.domain}/@${currentUser.username}`
        },
        recipientInfo: {
          id: recipient.id,
          username: recipient.username,
          displayName: recipient.displayName,
          avatar: recipient.avatar,
          acct: recipient.acct,
          url: `https://${instance.domain}/@${recipient.username}`
        }
      };

      await storeMessage(message, instance.domain);
      return true;
    } catch (error) {
      console.error('Error sending message:', error);
      return false;
    } finally {
      setIsLoading(false);
    }
  }, [instance, currentUser, activeConversation, conversations, isLoading]);

  return { sendMessage, isLoading };
}