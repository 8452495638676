import { supabase } from '../client';
import type { Message } from '../../../types/mastodon';
import { generateUserIdFromUrl } from '../../../utils/user/identifier';

export async function getThreadedMessages(conversationId: string, instanceDomain: string): Promise<Message[]> {
  console.log('Fetching threaded messages for conversation:', conversationId);

  try {
    const { data, error } = await supabase
      .from('messages')
      .select()
      .eq('conversation_id', conversationId)
      .eq('instance_domain', instanceDomain)
      .order('timestamp', { ascending: true });

    if (error) {
      console.error('Error fetching messages:', error);
      throw error;
    }

    if (!data) return [];

    console.log('Found messages:', data.length);

    return data.map(msg => ({
      id: msg.id,
      content: msg.content,
      sender: generateUserIdFromUrl(msg.sender_info.url),
      recipient: generateUserIdFromUrl(msg.recipient_info.url),
      timestamp: new Date(msg.timestamp),
      conversationId: msg.conversation_id,
      senderInfo: msg.sender_info,
      recipientInfo: msg.recipient_info
    }));

  } catch (error) {
    console.error('Error in getThreadedMessages:', error);
    return [];
  }
}