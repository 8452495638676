import { nanoid } from 'nanoid';
import { supabase } from '../client';
import { getDeviceInfo } from './device';
import type { UserInfo, UserSession } from '../../../types/mastodon';

export async function storeSession(
  userInfo: UserInfo, 
  domain: string, 
  accessToken: string
) {
  const sessionId = nanoid();
  const deviceInfo = getDeviceInfo();

  const { data, error } = await supabase
    .from('user_sessions')
    .upsert({
      session_id: sessionId,
      user_id: userInfo.id,
      domain,
      access_token: accessToken,
      user_info: userInfo,
      device_info: deviceInfo,
      last_active: new Date().toISOString()
    })
    .select()
    .single();

  if (error) throw error;

  localStorage.setItem('session_id', sessionId);
  return data;
}

export async function updateSessionActivity(sessionId: string) {
  const { error } = await supabase
    .from('user_sessions')
    .update({
      last_active: new Date().toISOString()
    })
    .eq('session_id', sessionId);

  if (error) throw error;
}

export async function clearSession(sessionId: string) {
  const { error } = await supabase
    .from('user_sessions')
    .delete()
    .eq('session_id', sessionId);

  if (error) throw error;
  
  localStorage.removeItem('session_id');
}

export async function listSessions(userId: string, domain: string): Promise<UserSession[]> {
  const { data, error } = await supabase
    .from('user_sessions')
    .select()
    .eq('user_id', userId)
    .eq('domain', domain)
    .order('last_active', { ascending: false });

  if (error) throw error;
  return data || [];
}

export async function getSession(sessionId: string): Promise<UserSession | null> {
  const { data, error } = await supabase
    .from('user_sessions')
    .select()
    .eq('session_id', sessionId)
    .single();

  if (error) return null;
  return data;
}