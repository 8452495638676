export function getUserTimezone(): string {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch {
    return 'UTC';
  }
}

export function formatToUserTimezone(date: Date | string): Date {
  const userTimezone = getUserTimezone();
  const inputDate = date instanceof Date ? date : new Date(date);
  
  return new Date(
    inputDate.toLocaleString('en-US', {
      timeZone: userTimezone
    })
  );
}

export function formatToUTC(date: Date | string): string {
  const inputDate = date instanceof Date ? date : new Date(date);
  return inputDate.toISOString();
}