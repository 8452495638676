import { supabase } from '../client';
import type { Message } from '../../../types/mastodon';
import type { RealtimeChannel } from '@supabase/supabase-js';

export function subscribeToMessages(
  conversationId: string,
  instanceDomain: string,
  onMessage: (message: Message) => void
): RealtimeChannel {
  const channel = supabase
    .channel(`messages:${conversationId}`)
    .on(
      'postgres_changes',
      {
        event: 'INSERT',
        schema: 'public',
        table: 'messages',
        filter: `conversation_id=eq.${conversationId} and instance_domain=eq.${instanceDomain}`
      },
      (payload) => {
        const message = {
          id: payload.new.id,
          content: payload.new.content,
          sender: payload.new.sender,
          recipient: payload.new.recipient,
          timestamp: new Date(payload.new.timestamp),
          conversationId: payload.new.conversation_id,
          senderInfo: payload.new.sender_info,
          recipientInfo: payload.new.recipient_info
        };
        onMessage(message);
      }
    )
    .subscribe();

  return channel;
}