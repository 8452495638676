import { getUserIdentifier } from '../../services/api/webfinger';
import { parseUrl } from './url';
import { normalizeUsername } from './username';

export async function generateUserId(acct: string, domain: string): Promise<string> {
  const cleanUsername = normalizeUsername(acct);
  const userDomain = cleanUsername.includes('@') ? 
    cleanUsername.split('@')[1] : 
    domain;
  
  const username = cleanUsername.split('@')[0];
  
  // Get canonical identifier via WebFinger
  return await getUserIdentifier(username, userDomain);
}

export function generateUserIdFromUrl(url: string | undefined): string {
  if (!url) return '';
  
  const { username, domain } = parseUrl(url);
  if (!username || !domain) return '';
  
  return `${username}@${domain}`;
}