import React, { useState } from 'react';
import { Search, Send } from 'lucide-react';
import { useMastodonStore } from '../../store/mastodonStore';
import { searchUsers } from '../../services/api/userApi';
import type { UserInfo } from '../../types/mastodon';
import { generateUserId } from '../../utils/user/identifier';
import { storeMessage } from '../../services/supabase/messages/store';
import { nanoid } from 'nanoid';

export function NewConversation({ onClose }: { onClose: () => void }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<UserInfo[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserInfo | null>(null);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const instance = useMastodonStore((state) => state.instance);
  const currentUser = useMastodonStore((state) => state.currentUser);
  const addConversation = useMastodonStore((state) => state.addConversation);
  const setActiveConversation = useMastodonStore((state) => state.setActiveConversation);

  const handleSearch = async () => {
    if (!instance || !searchQuery.trim() || isLoading) return;
    
    setIsLoading(true);
    try {
      const results = await searchUsers(
        instance.domain,
        instance.accessToken,
        searchQuery.trim()
      );
      setSearchResults(results);
    } catch (error) {
      console.error('Error searching users:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartConversation = async () => {
    if (!instance || !currentUser || !selectedUser || !message.trim() || isLoading) return;

    setIsLoading(true);
    try {
      const messageId = nanoid();
      const conversationId = nanoid();
      
      // Generate WebFinger-based IDs
      const senderId = await generateUserId(currentUser.acct, instance.domain);
      const recipientId = await generateUserId(selectedUser.acct, instance.domain);

      // Store message in Supabase
      const messageData = {
        id: messageId,
        content: message.trim(),
        sender: senderId,
        recipient: recipientId,
        timestamp: new Date(),
        conversationId: conversationId,
        senderInfo: {
          id: currentUser.id,
          username: currentUser.username,
          displayName: currentUser.displayName,
          avatar: currentUser.avatar,
          acct: currentUser.acct,
          url: `https://${instance.domain}/@${currentUser.username}`
        },
        recipientInfo: {
          id: selectedUser.id,
          username: selectedUser.username,
          displayName: selectedUser.displayName,
          avatar: selectedUser.avatar,
          acct: selectedUser.acct,
          url: `https://${instance.domain}/@${selectedUser.username}`
        }
      };

      await storeMessage(messageData, instance.domain);

      addConversation({
        id: conversationId,
        participants: [selectedUser],
        lastMessage: messageData,
        unread: false
      });
      
      // Set active conversation and close the pane
      setActiveConversation(conversationId);
      onClose();
    } catch (error) {
      console.error('Error starting conversation:', error);
      // Show error message to user
      alert('Failed to start conversation. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-4 border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
      {!selectedUser ? (
        <div className="space-y-4">
          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
              onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
              placeholder="Search users..."
              className="flex-1 px-4 py-2 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400"
              disabled={isLoading}
            />
            <button
              onClick={handleSearch}
              disabled={isLoading}
              className={`p-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors ${
                isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'
              }`}
              title={isLoading ? 'Searching...' : 'Search'}
              aria-label={isLoading ? 'Searching...' : 'Search'}
              aria-disabled={isLoading}
            >
              {isLoading ? (
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
              ) : (
                <Search className="w-5 h-5" />
              )}
            </button>
          </div>
          {searchResults.length > 0 && (
            <div className="space-y-2">
              {searchResults.map((user) => (
                <button
                  key={user.id}
                  onClick={() => setSelectedUser(user)}
                  className="w-full p-2 text-left hover:bg-gray-50 dark:hover:bg-gray-700 rounded-lg flex items-center space-x-3"
                >
                  <img
                    src={user.avatar}
                    alt={user.displayName}
                    className="w-10 h-10 rounded-full"
                  />
                  <div>
                    <p className="font-medium dark:text-white">{user.displayName}</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">{user.acct}</p>
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className="space-y-4">
          <div className="flex items-center space-x-3">
            <img
              src={selectedUser.avatar}
              alt={selectedUser.displayName}
              className="w-10 h-10 rounded-full"
            />
            <div>
              <p className="font-medium dark:text-white">{selectedUser.displayName}</p>
              <p className="text-sm text-gray-500 dark:text-gray-400">{selectedUser.acct}</p>
            </div>
            <button
              onClick={() => setSelectedUser(null)}
              className="ml-auto text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
            >
              Cancel
            </button>
          </div>
          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && handleStartConversation()}
              placeholder="Type your first message..."
              className="flex-1 px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
              disabled={isLoading}
            />
            <button
              onClick={handleStartConversation}
              disabled={!message.trim() || isLoading}
              className={`p-2 text-white bg-blue-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors ${
                !message.trim() || isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'
              }`}
              title={isLoading ? 'Sending...' : 'Send message'}
              aria-label={isLoading ? 'Sending...' : 'Send message'}
            >
              <Send className="w-5 h-5" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}