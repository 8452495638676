import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSession } from './useSession';
import { getSession } from '../../services/supabase/auth/session';

const HEARTBEAT_INTERVAL = 30000; // 30 seconds

export function useSessionHeartbeat() {
  const navigate = useNavigate();
  const { updateActivity, logout } = useSession();
  const heartbeatRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const checkSession = async () => {
      const sessionId = localStorage.getItem('session_id');
      if (!sessionId) return;

      const session = await getSession(sessionId);
      
      if (!session) {
        // Session was revoked
        await logout();
        navigate('/');
        return;
      }

      // Update last active timestamp
      await updateActivity();
    };

    // Start heartbeat
    heartbeatRef.current = setInterval(checkSession, HEARTBEAT_INTERVAL);

    // Initial check
    checkSession();

    return () => {
      if (heartbeatRef.current) {
        clearInterval(heartbeatRef.current);
      }
    };
  }, [navigate, updateActivity, logout]);
}