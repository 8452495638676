import { Message, MessageGroup } from '../../types/mastodon';
import { ensureDate } from '../date/comparison';
import { getDomainFromAcct } from './domain';

export function groupMessagesByUser(messages: Message[]): Message[] {
  const userGroups = new Map<string, Message[]>();

  // Sort messages by timestamp first
  const sortedMessages = [...messages].sort((a, b) => 
    ensureDate(a.timestamp).getTime() - ensureDate(b.timestamp).getTime()
  );

  // Group messages by user
  sortedMessages.forEach(message => {
    const userKey = message.senderInfo.id;
    if (!userGroups.has(userKey)) {
      userGroups.set(userKey, []);
    }
    userGroups.get(userKey)!.push(message);
  });

  // Merge consecutive messages from the same user
  const mergedMessages: Message[] = [];
  Array.from(userGroups.values()).forEach(userMessages => {
    let currentMessage: Message | null = null;

    userMessages.forEach(message => {
      if (!currentMessage) {
        currentMessage = { ...message };
        mergedMessages.push(currentMessage);
      } else {
        // If messages are within 5 minutes, merge them
        const timeDiff = ensureDate(message.timestamp).getTime() - 
                        ensureDate(currentMessage.timestamp).getTime();
        
        if (timeDiff <= 5 * 60 * 1000) {
          currentMessage.content += '\n' + message.content;
        } else {
          currentMessage = { ...message };
          mergedMessages.push(currentMessage);
        }
      }
    });
  });

  return mergedMessages;
}

export function groupMessagesByDate(messages: Message[]): MessageGroup[] {
  if (!Array.isArray(messages)) return [];
  
  // First group messages by user
  const userGroupedMessages = groupMessagesByUser(messages);
  
  const groups: MessageGroup[] = [];
  
  userGroupedMessages.forEach(message => {
    const messageDate = ensureDate(message.timestamp);
    messageDate.setHours(0, 0, 0, 0);

    const existingGroup = groups.find(group => 
      group.date.getTime() === messageDate.getTime()
    );

    if (existingGroup) {
      existingGroup.messages.push(message);
    } else {
      groups.push({
        date: messageDate,
        messages: [message]
      });
    }
  });

  return sortMessageGroups(groups);
}

function sortMessageGroups(groups: MessageGroup[]): MessageGroup[] {
  groups.sort((a, b) => a.date.getTime() - b.date.getTime());

  groups.forEach(group => {
    group.messages.sort((a, b) => 
      ensureDate(a.timestamp).getTime() - ensureDate(b.timestamp).getTime()
    );
  });

  return groups;
}