import { supabase } from '../client';
import type { Message } from '../../../types/mastodon';
import { generateUserId } from '../../../utils/user/identifier';
import { formatToUTC } from '../../../utils/date/timezone';

export async function storeMessage(message: Message, instanceDomain: string) {
  if (!message.senderInfo || !message.recipientInfo) {
    throw new Error('Message must have both sender and recipient information');
  }

  const senderId = await generateUserId(message.senderInfo.acct, instanceDomain);
  const recipientId = await generateUserId(message.recipientInfo.acct, instanceDomain);

  const { error } = await supabase
    .from('messages')
    .insert({
      id: message.id,
      content: message.content,
      sender: senderId,
      recipient: recipientId,
      timestamp: formatToUTC(message.timestamp),
      conversation_id: message.conversationId,
      instance_domain: instanceDomain,
      sender_info: message.senderInfo,
      recipient_info: message.recipientInfo
    });

  if (error) throw error;
}