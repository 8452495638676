import { supabase } from '../supabase/client';

interface WebFingerLink {
  rel: string;
  type?: string;
  href: string;
  template?: string;
}

interface WebFingerResponse {
  subject: string;
  aliases?: string[];
  links: WebFingerLink[];
}

async function lookupWebFinger(username: string, domain: string): Promise<WebFingerResponse | null> {
  try {
    const resource = encodeURIComponent(`acct:${username}@${domain}`);
    const url = `https://${domain}/.well-known/webfinger?resource=${resource}`;
    
    // Use CORS proxy to avoid CORS issues
    const response = await fetch(`https://corsproxy.io/?${encodeURIComponent(url)}`);
    
    if (!response.ok) {
      throw new Error(`WebFinger lookup failed: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('WebFinger lookup failed:', error);
    return null;
  }
}

export async function getUserIdentifier(username: string, domain: string): Promise<string> {
  // First check cache
  const { data: cached } = await supabase
    .from('webfinger_cache')
    .select('canonical_url')
    .eq('username', username.toLowerCase())
    .eq('domain', domain.toLowerCase())
    .single();

  if (cached?.canonical_url) {
    return cached.canonical_url;
  }

  // If not in cache, do WebFinger lookup
  const webfinger = await lookupWebFinger(username, domain);
  if (!webfinger) {
    // Fallback to local format if WebFinger fails
    return `${username}@${domain}`;
  }

  // Find the canonical profile URL
  const profileLink = webfinger.links.find(link => 
    link.rel === 'http://webfinger.net/rel/profile-page' &&
    link.type === 'text/html'
  );

  const canonicalUrl = profileLink?.href || webfinger.aliases?.[0] || `${username}@${domain}`;

  // Cache the result
  await supabase.from('webfinger_cache').upsert({
    username: username.toLowerCase(),
    domain: domain.toLowerCase(),
    canonical_url: canonicalUrl,
    data: webfinger
  });

  return canonicalUrl;
}