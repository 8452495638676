import React from 'react';
import clsx from 'clsx';
import { formatRelativeTime } from '../../utils/date/formatting';
import { Message } from '../../types/mastodon';
import { MessageContent } from './MessageContent';

interface MessageBubbleProps {
  message: Message;
  isCurrentUser: boolean;
  isFirstInGroup: boolean;
  isLastInGroup: boolean;
}

export function MessageBubble({
  message,
  isCurrentUser,
  isFirstInGroup,
  isLastInGroup,
}: MessageBubbleProps) {
  return (
    <div
      className={clsx(
        'flex items-end gap-2 animate-fade-in mb-2',
        isCurrentUser ? 'justify-end' : 'justify-start',
        !isLastInGroup && 'mb-2'
      )}
    >
      {!isCurrentUser && isFirstInGroup && (
        <img
          src={message.senderInfo.avatar}
          alt={message.senderInfo.displayName}
          className="w-8 h-8 rounded-full flex-shrink-0 border border-gray-200 dark:border-gray-700 shadow-sm mt-1"
        />
      )}
      {!isCurrentUser && !isFirstInGroup && <div className="w-8 flex-shrink-0" />}
      
      <div className="flex flex-col max-w-[75%]">
        {isFirstInGroup && !isCurrentUser && (
          <span className="text-xs text-gray-500 dark:text-gray-400 ml-2 mb-0.5">
            {message.senderInfo.displayName}
          </span>
        )}
        <div
          className={clsx(
            'px-3.5 py-2 rounded-2xl break-words shadow-sm transition-colors',
            isCurrentUser
              ? 'bg-blue-500 text-white hover:bg-blue-600'
              : 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700',
            isFirstInGroup && isCurrentUser && 'rounded-tr-md',
            isFirstInGroup && !isCurrentUser && 'rounded-tl-md',
            isLastInGroup && isCurrentUser && 'rounded-br-md',
            isLastInGroup && !isCurrentUser && 'rounded-bl-md'
          )}
        >
          <MessageContent content={message.content} />
          <span className={clsx(
            'text-[10px] block mt-1 opacity-75',
            isCurrentUser ? 'text-blue-50' : 'text-gray-500 dark:text-gray-400'
          )}>
            {formatRelativeTime(message.timestamp)}
          </span>
        </div>
      </div>
    </div>
  );
}