import { useCallback, useState } from 'react';
import { useMastodonStore } from '../../store/mastodonStore';
import { fetchConversations } from '../../services/supabase/conversations/fetch';

export function useConversationList(limit = 20) {
  const instance = useMastodonStore((state) => state.instance);
  const setConversations = useMastodonStore((state) => state.setConversations);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const loadConversations = useCallback(async (params?: { maxId?: string; minId?: string }) => {
    if (!instance) return;

    console.log('Loading conversations...');
    setIsLoading(true);
    try {
      const conversations = await fetchConversations(instance.domain, offset, limit);
      
      console.log('Loaded conversations:', conversations);

      if (conversations.length < limit) {
        setHasMore(false);
      }
      
      if (offset === 0) {
        console.log('Setting initial conversations');
        setConversations(conversations);
      } else {
        console.log('Appending conversations');
        setConversations(prev => [...prev, ...conversations]);
      }
      
      setOffset(prev => prev + conversations.length);
    } catch (error) {
      console.error('Error loading conversations:', error);
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  }, [instance, offset, limit, setConversations]);

  const loadMore = useCallback(() => {
    if (!isLoading && hasMore) {
      console.log('Loading more conversations...');
      loadConversations();
    }
  }, [isLoading, hasMore, loadConversations]);

  const refresh = useCallback(() => {
    console.log('Refreshing conversations...');
    setOffset(0);
    setHasMore(true);
    console.log('Initial conversations load');
    loadConversations();
  }, [loadConversations]);

  return {
    isLoading,
    loadMore,
    refresh,
    hasMore
  };
}