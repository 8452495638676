import { useCallback } from 'react';
import { useMastodonStore } from '../../store/mastodonStore';
import { storeSession, clearSession, updateSessionActivity } from '../../services/supabase/auth/session';
import type { UserInfo } from '../../types/mastodon';

export function useSession() {
  const setInstance = useMastodonStore((state) => state.setInstance);
  const setCurrentUser = useMastodonStore((state) => state.setCurrentUser);
  const clearStore = useMastodonStore((state) => state.clearStore);

  const saveSession = useCallback(async (
    userInfo: UserInfo,
    domain: string,
    accessToken: string
  ) => {
    await storeSession(userInfo, domain, accessToken);
    setInstance({ domain, accessToken });
    setCurrentUser(userInfo);
  }, [setInstance, setCurrentUser]);

  const updateActivity = useCallback(async () => {
    const sessionId = localStorage.getItem('session_id');
    if (sessionId) {
      await updateSessionActivity(sessionId);
    }
  }, []);

  const logout = useCallback(async () => {
    const sessionId = localStorage.getItem('session_id');
    if (sessionId) {
      await clearSession(sessionId);
    }
    await clearStore();
  }, [clearStore]);

  return { saveSession, updateActivity, logout };
}