import React, { useState } from 'react';
import { Plus, User } from 'lucide-react';
import { useMastodonStore } from '../../store/mastodonStore';
import { useConversationList } from '../../hooks/conversation/useConversationList';
import { ConversationItem } from './ConversationItem';
import { NewConversation } from './NewConversation';

interface ConversationListProps {
  onConversationSelect?: () => void;
}

export function ConversationList({ onConversationSelect }: ConversationListProps) {
  const [showNewConversation, setShowNewConversation] = useState(false);
  const { isLoading, loadMore, hasMore } = useConversationList();
  const conversations = useMastodonStore((state) => state.conversations);
  const activeConversation = useMastodonStore((state) => state.activeConversation);
  const setActiveConversation = useMastodonStore((state) => state.setActiveConversation);

  const handleConversationSelect = (conversationId: string) => {
    setActiveConversation(conversationId);
    onConversationSelect?.();
  };

  return (
    <div className="w-full h-full flex flex-col bg-white dark:bg-gray-800">
      <div className="p-4 border-b border-gray-200 dark:border-gray-700 flex items-center justify-between">
        <h2 className="text-lg font-semibold dark:text-white">Conversations</h2>
        <button
          onClick={() => setShowNewConversation(!showNewConversation)}
          className="p-2 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
        >
          <Plus className="w-5 h-5" />
        </button>
      </div>
      
      {showNewConversation && (
        <NewConversation 
          onClose={() => {
            setShowNewConversation(false);
            onConversationSelect?.();
          }} 
        />
      )}

      <div className="flex-1 overflow-y-auto">
        {conversations.map((conversation) => (
          <ConversationItem
            key={conversation.id}
            conversation={conversation}
            isActive={conversation.id === activeConversation}
            onClick={() => handleConversationSelect(conversation.id)}
          />
        ))}

        {hasMore && (
          <button
            onClick={loadMore}
            className="w-full p-4 text-center text-blue-600 dark:text-blue-400 hover:bg-gray-50 dark:hover:bg-gray-700"
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Load More'}
          </button>
        )}
      </div>
    </div>
  );
}