export function ensureDate(date: Date | string | null | undefined): Date {
  if (!date) return new Date();
  
  // If already a Date object and valid, return it
  if (date instanceof Date) {
    if (isNaN(date.getTime())) {
      return new Date();
    }
    return date;
  }
  
  try {
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return new Date();
    }
    return parsedDate;
  } catch {
    return new Date();
  }
}