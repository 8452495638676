import axios from 'axios';

interface GeoLocation {
  city?: string;
  region?: string;
  country?: string;
  timezone?: string;
}

export async function getGeoLocation(): Promise<GeoLocation | null> {
  try {
    const response = await axios.get('https://geo.anime.systems/json');
    return response.data;
  } catch (error) {
    console.error('Error fetching geo location:', error);
    return null;
  }
}