import React from 'react';

interface MessageContentProps {
  content: string;
}

export function MessageContent({ content }: MessageContentProps) {
  const messageLines = content.split('\n');

  return (
    <div className="text-sm whitespace-pre-wrap break-words">
      {messageLines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index < messageLines.length - 1 && <br />}
        </React.Fragment>
      ))}
    </div>
  );
}