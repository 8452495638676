interface ParsedUrl {
  username: string;
  domain: string;
}

export function parseUrl(url: string): ParsedUrl {
  try {
    const urlObj = new URL(url);
    const parts = urlObj.pathname.split('/');
    const username = parts[parts.length - 1]?.replace('@', '');
    
    return {
      username: username || '',
      domain: urlObj.hostname
    };
  } catch {
    return { username: '', domain: '' };
  }
}

export function getInstanceUrl(url: string): string {
  try {
    const urlObj = new URL(url);
    return urlObj.origin;
  } catch {
    return '';
  }
}