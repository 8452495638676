import React, { useEffect, useRef } from 'react';
import { useMastodonStore } from '../../store/mastodonStore';
import { useMessages } from '../../hooks/useMessages';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { MessageBubble } from './MessageBubble';
import { groupMessagesByDate } from '../../utils/message/grouping';

export function MessageList() {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { isLoading, isSyncing } = useMessages();
  const currentUser = useMastodonStore((state) => state.currentUser);
  const activeConversation = useMastodonStore((state) => state.activeConversation);
  const messages = useMastodonStore((state) => 
    activeConversation ? state.messages[activeConversation] || [] : []
  );

  const messageGroups = groupMessagesByDate(messages);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  if (isLoading && messages.length === 0) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <div className="text-center">
          <LoadingSpinner size="lg" className="mx-auto mb-4" />
          <p className="text-gray-500 dark:text-gray-400">
            {isSyncing ? 'Syncing messages...' : 'Loading messages...'}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex-1 overflow-y-auto p-4 space-y-4 bg-gray-50 dark:bg-gray-900">
      {messageGroups.map((group, groupIndex) => (
        <div key={group.date.toISOString()} className="space-y-2">
          <div className="flex items-center justify-center">
            <div className="text-xs text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-800 px-3 py-1 rounded-full shadow-sm">
              {group.date.toLocaleDateString()}
            </div>
          </div>
          {group.messages.map((message, messageIndex) => {
            const isCurrentUser = currentUser && message.sender === currentUser.id;
            const isFirstInGroup = messageIndex === 0 || 
              group.messages[messageIndex - 1].senderInfo.id !== message.senderInfo.id;
            const isLastInGroup = messageIndex === group.messages.length - 1 || 
              group.messages[messageIndex + 1].senderInfo.id !== message.senderInfo.id;
            
            return (
              <MessageBubble
                key={message.id}
                message={message}
                isCurrentUser={isCurrentUser}
                isFirstInGroup={isFirstInGroup}
                isLastInGroup={isLastInGroup}
              />
            );
          })}
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
}