import { useEffect, useCallback, useState } from 'react';
import { useMastodonStore } from '../store/mastodonStore';
import { getThreadedMessages } from '../services/supabase/messages/sync';
import { subscribeToMessages } from '../services/supabase/messages/subscription';

export function useMessages() {
  const instance = useMastodonStore((state) => state.instance);
  const activeConversation = useMastodonStore((state) => state.activeConversation);
  const setMessages = useMastodonStore((state) => state.setMessages);
  const [isLoading, setIsLoading] = useState(true);

  const loadMessages = useCallback(async () => {
    if (!instance || !activeConversation) return;

    console.log('Loading messages for conversation:', activeConversation);
    setIsLoading(true);
    try {
      // Get threaded messages from Supabase
      const messages = await getThreadedMessages(activeConversation, instance.domain);
      
      if (messages.length > 0) {
        console.log('Loaded messages:', messages);
        setMessages(activeConversation, messages);
      } else {
        console.log('No messages found for conversation');
      }
    } catch (error) {
      console.error('Error loading messages:', error);
    } finally {
      setIsLoading(false);
    }
  }, [instance, activeConversation, setMessages]);

  // Set up real-time subscription
  useEffect(() => {
    if (!instance || !activeConversation) return;

    console.log('Setting up real-time subscription');
    const subscription = subscribeToMessages(
      activeConversation,
      instance.domain,
      (message) => {
        console.log('Received real-time message:', message.id);
        setMessages(activeConversation, prev => [...prev, message]);
      }
    );

    return () => {
      console.log('Cleaning up subscription');
      subscription.unsubscribe();
    };
  }, [instance, activeConversation, setMessages]);

  // Initial load
  useEffect(() => {
    if (activeConversation) {
      loadMessages();
    }
  }, [activeConversation, loadMessages]);

  return { isLoading, loadMessages };
}