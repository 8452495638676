import { Routes, Route } from 'react-router-dom';
import { ChatLayout, LoginForm, OAuthCallback, UserRedirect } from './components';
import { useMastodonStore } from './store/mastodonStore';
import { useSessionHeartbeat } from './hooks/auth/useSessionHeartbeat';

export default function App() {
  const instance = useMastodonStore((state) => state.instance);
  useSessionHeartbeat(); // Add session heartbeat

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      {!instance ? (
        <div className="flex items-center justify-center p-4">
          <Routes>
            <Route path="/oauth/callback" element={<OAuthCallback />} />
            <Route path="/t/:handle" element={<UserRedirect />} />
            <Route path="*" element={<LoginForm />} />
          </Routes>
        </div>
      ) : (
        <Routes>
          <Route path="/t/:handle" element={<ChatLayout />} />
          <Route path="*" element={<ChatLayout />} />
        </Routes>
      )}
    </div>
  );
}